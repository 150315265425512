import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clp-ugeneral',
  template: ``,
  providers: []
})

export class UGeneralComp implements OnInit {

  ngOnInit() { }

}
