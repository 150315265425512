import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { setEmployeeHrd } from '@clp/auth/auth.initializer';
import { delay, filter, map, Subscription, tap } from 'rxjs';
import { AuthService } from '../auth';

@Component({
  selector: 'clp-logged-out',
  styles: [
    ':host {display:flex; overflow: hidden; }',
  ],
  template: `
<div class="p-4 text-center flex-grow-1 mt-4" *ngIf="showContent | async">
    <h4 class="display-5 mt-4" i18n>You must login to proceed.</h4>
    <div class="lead mt-3">
      <button class="btn btn-primary-alt" (click)="onLoginClick()" i18n>Login</button>
    </div>
    <clp-footer-tagline-support></clp-footer-tagline-support>
    <div class="col-12 col-sm-8 offset-sm-2">
      Employee? <a href="javascript:void(0)" (click)="onEmployeeLoginClick()">Click Here</a>
    </div>
</div>
`
})

export class LoggedOutComp implements OnDestroy {

  public hasCodeArgument = false;
  public static readonly authenticatedFallbackDelayMs = 1500;

  private redirectFallbackSubscription: Subscription | undefined;

  public get showContent() {
    return this.authSvc.isAuthenticated$.pipe(
      map(x => !x.isAuthenticated && !this.hasCodeArgument),
    );
  }

  constructor(public authSvc: AuthService, private router: Router) {
    this.detectCodeArgument();
    this.redirectAuthenticatedUserFallback();
  }

  ngOnDestroy(): void {
    if (!!this.redirectFallbackSubscription) {
      this.redirectFallbackSubscription.unsubscribe();
    }
  }

  private detectCodeArgument() {
    const url = this.router.routerState.snapshot.url;
    if (!!url && url.startsWith('/?code=')) {
      this.hasCodeArgument = true;
    }
  }

  private redirectAuthenticatedUserFallback() {
    this.redirectFallbackSubscription = this.authSvc.isAuthenticated.pipe(
      filter(x => x),
      delay(LoggedOutComp.authenticatedFallbackDelayMs),
      tap(x => this.router.navigate(['u']))
    ).subscribe();
  }

  public onLoginClick() {
    this.authSvc.login();
  }

  public onEmployeeLoginClick() {
    setEmployeeHrd();
    this.authSvc.login();
  }
}
