<div class="mb-4 jumbotron jumbotron-fluid py-4 border-bottom noselect">
  <h4 class="display-5 ps-5 text-wrap" i18n>Please select the application you would like to use.</h4>
</div>
<div class="tile-group-container noselect" *ngFor="let tileGroup of tileSvc.tileGroups$ | async">
  <div class="tile-group-label display-5 text-secondary">{{tileGroup.groupName}}</div>
  <hr class="tile-group-separator" />
  <div class="tile-container">
    <div class="tile border shadow-sm" *ngFor="let appTile of tileGroup.tiles; let last = last;">
      <ng-container *ngIf="appTile.routerLink">
        <button class="btn btn-link appTile" [routerLink]="appTile.routerLink">
          <img [src]="appTile.imgSrc" [alt]="appTile.imgAlt" [style.height]="appTile.imgHeight" />
        </button>
      </ng-container>
      <ng-container *ngIf="!appTile.routerLink">
        <button class="btn btn-link appTile" (click)="onHrefClick(appTile.href!)">
          <img [src]="appTile.imgSrc" [alt]="appTile.imgAlt" [style.height]="appTile.imgHeight" />
        </button>
      </ng-container>
    </div>
  </div>
</div>
