import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from '../layout';
import { LoggedOutComp } from './logged-out.comp';
import { UGeneralComp } from './u-general.comp';
import { AppDashComp } from './app-dash.comp';
import { UnauthorizedComp } from '@clp/auth';
import { UCommonModule } from '@clp/u-common/u-common.m';
import { ReactiveFormsModule } from '@angular/forms';
import { RoleRootGuard } from '@clp/i-perm';
import { UserProfileComp } from './user-profile.comp';

export const appGeneralRoutes = {
  landing: 'landing',
  login: 'login',
  relativity: 'relativity',
  sightline: 'sightline',
  support: 'support',
  tools: 'tools',
  unauthorized: 'unauthorized',
  profile: 'profile'
};

const routes: Routes = [
  {
    path: appGeneralRoutes.landing,
    component: AppDashComp,
  },
  {
    path: appGeneralRoutes.relativity,
    loadChildren: () => import('@clp/u-relativity').then(x => x.URelativityModule)
  },
  {
    path: appGeneralRoutes.tools,
    canActivate: [RoleRootGuard],
    loadChildren: () => import('@clp/u-tools').then(x => x.UToolsModule)
  },
  {
    path: appGeneralRoutes.profile,
    component: UserProfileComp
  },
  {
    path: appGeneralRoutes.unauthorized,
    component: UnauthorizedComp
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: appGeneralRoutes.landing
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    LayoutModule,
    ReactiveFormsModule,
    UCommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: [
    UGeneralComp,
    LoggedOutComp,
    AppDashComp,
    UserProfileComp
  ],
  providers: [],
})
export class UGeneralModule { }
