<div class="p-4">
  <div class="mb-3">
    <h4 class="display-4">User Profile</h4>
    <hr/>
  </div>
  <div>
    <div>
      <label class="mb-0">Username</label>
      <div class="rowset-value"><h4 class="display-5">{{info.upn}}</h4></div>
    </div>
    <div>
      <label class="mb-0">Provider Uid</label>
      <div class="rowset-value"><h4 class="display-5">{{info.idp}}</h4></div>
    </div>
    <div>
      <label class="mb-0">Provider Name</label>
      <div class="rowset-value"><h4 class="display-5">{{info.idpName}}</h4></div>
    </div>
    <div class="my-2">&nbsp;</div>
    <div>
      <label class="mb-0">Given Name</label>
      <div class="rowset-value"><h4 class="display-5">{{info.given_name}}</h4></div>
    </div>
    <div>
      <label class="mb-0">Family Name</label>
      <div class="rowset-value"><h4 class="display-5">{{info.family_name}}</h4></div>
    </div>
  </div>

  <ng-container *ngIf="showActions">
    <div class="my-4">
      <h4 class="display-4">Actions</h4>
      <hr/>
    </div>
    <div>
      <button *ngIf="canClearMfa" type="button" class="btn btn-outline-primary-alt px-4" (click)="onClickClearMfa()">Clear Mfa Preference</button>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="debug">
  <pre>{{info | json}}</pre>
  <pre>{{me | json}}</pre>
</ng-container>
